import styled, { StyledComponent } from "styled-components";
import React from "react";
import tickMark from '../public/images/home-page-assets/tick-mark.png';
import bg from '../public/images/home-page-assets/topographic-tile.png';
import contactBg from '../public/images/contactus-banner.jpg';
export const CommonStyle = styled.div`
.image-background{
  background-repeat:no-repeat;
  background-size:cover;
}
.special-label{
  display:none;
}
.react-tel-input .form-control{
  display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.select-form-control{
  display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding-right: 1rem !important;
    padding-left: 0.5rem !important;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.big-title {
  font-size: 22px;
  font-weight: 900;
}
.light-weight{
  font-weight:500 !important;
}
.scale-top{ 
  height: 500px;
  transition:all .3s;
  transform-origin:bottom;
}
.scale-top:hover{
  transform:scale(105%);
}

.100vh{
  height:100vh !important;
}
.mt-80{
  margin-top: 80px;
}
.pb-80{
  padding-bottom:80px !important;
}
.caption h3{
font-weight: 300 !important;
 color: #77838F;
}
.bg-light-white{
    background:#F8F8F8;
}
.box-shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
.heavy-bold {
    font-weight: 900 !important;
  }
  .zip-code-list li::marker{
    color:#000
  }
  .team-border-cover{
    pointer-events:none;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    height:210px;
    width:210px;
    overflow:hidden;
    border:1px solid red;
  }
  .carousel-profile.border-cover{
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    height:45px;
    width:45px;
    overflow:hidden;
  }
  .quote-circular-badge{
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    height:50px;
    width:50px;
    border-radius:50%;
    top:-70px;
    left:20px;
  }

  .themed-bg{
    background-image:url('${bg.src}');
    .email-field:focus{
box-shadow:none !important;
    }
  }


  .tech-grid{
  text-align:center;

  }
 
}
.dealer-grid{
  display:grid;
  grid-template-columns:1fr 1fr;
  column-gap:100px;
  @media(min-width:920px){
  .left-section{
    text-align:right;
  }
}
  .heading{
    font-size:2.4rem;
    font-weight:bold;
    margin-bottom:30px;
  }
  .title{
    font-weight:bold;
    font-size:18px;
  }
  .description{
    color:#707070;
  }
  
  .grid-section{
    width:100%;
    margin-bottom:30px;
    p{
      margin-bottom:0 !important;
    }
  }

  .left-section{
    position:relative;
    .grid-section{
      position:relative;
    }
    @media(min-width:920px){
    .grid-section::after{
     content:'';
     width:12px;
     height:12px;
     background:#F41E14;
     border-radius:50%;
     position:absolute;
     right:-60px;
     top:8px;
     z-index:3;
    }
  }

  }
  .left-section::after{
    content:'';
     width:3px;
     height:100%;
     background:#EBEBEB;
     position:absolute;
     right:-55px;
     top:0px;
     z-index:0;
  }
  @media(max-width:920px){    
   .left-section::after{
    display:none;
     }
  }
}
@media(max-width:920px){

.dealer-grid{
 display:block;
}
}

  .tech-grid{
    .grid-item{
      position:relative;
      align-self:center;
      justify-items:space-between;
      color:#707070;
      p{
        line-height:1.5;
      }
    }
    .grid-item:hover{
      color:#000;
    }
    .grey-area{
      grid-area:grey-area;
      background-color:transparent !important;
    } 
  .grid-item:not(.grey-area)::after{
    content:"";
    width:50px;
    height:50px;
    background-image:url(${tickMark.src});
    background-size:cover;
    position:absolute;
    top:-20px;
    left:50%;
    transform:translateX(-50%);

  }
  .grid-item:not(.grey-area):hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  }


@media screen and (max-width: 768px){
  
.translate-x-25{
  transform: translateX(25%) 
 }
 .translate-x-0{
  transform: translateX(-25%)
 }

.caption h1 {
    font-size: 1.6rem !important;
}
.caption h2{
    font-size: 1.4rem !important;
}
}
@media screen and (max-width: 920px){
.image-background{
  background-position:0 !important;
}}
@media screen and (min-width: 768px){
  
.text-md-left{
  text-align:left !important;
  }
  .w-md-auto{
    width:auto !important;
  }
 
  .themed-bg{
    .email-field{
      width:300px;
    }
  }
}
@media screen and (min-width: 920px){
  .tech-grid{
    display:grid;
    grid-template-columns:230px 230px 230px 230px;
    grid-template-rows:150px 150px;
    row-gap:100px;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    grid-template-areas:
    ". grey-area grey-area ."
    ". grey-area grey-area .";
  }
  .side-by-side{
    transform:translate(0,60px);
  }
.w-lg-auto{
  width:auto !important;
}
}
@media screen and (max-width: 1199px)
.caption h1 {
    font-size: 2rem !important;
}
.caption h2{
    font-size: 1.8rem !important;
}
.center-element{
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
.end-element{
  top:50%;
  right:0;
  transform:translate(-50%,-50%);
}
.hover-black:hover{
  color:#000 !important;
  border-bottom: 2px solid #000;
}

@media screen and (min-width: 1300px){
  .tech-grid{
    display:grid;
    grid-template-columns:280px 280px 280px 280px;
    grid-template-rows:150px 150px;
    row-gap:100px;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    grid-template-areas:
    ". grey-area grey-area ."
    ". grey-area grey-area .";
  }
  .side-by-side{
    transform:translate(0);
  }
}

.zoom-in-out-box {
  
  animation: zoom-in-zoom-out 1s ease 1;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.01, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const ContactForm = styled.div`
.top-banner {
  z-index: 1;
  position: relative;
}
.contact{
  background: url(${contactBg.src}) no-repeat;
    background-size: cover;
    background-position: center top;
    height: 84vh;
}
h3 {
  color: rgba(255,255,255,.65);
  font-size: 1.45rem;
  font-weight: 300 !important;
}
h2{
  font-size:2rem !important;
}
.caption {
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  color: #fff;
  h1{
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.1em;
  }
  
 
}
`
export const PartnerForm = styled.div`
h4{
  font-size: 1.5rem !important;
}
ul{
  list-style-type: disc !important;
  li::marker {
    color:#000;
}
li{
  margin-bottom:10px;
}

}
`;