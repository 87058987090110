import router from "next/router";
import React from "react";
import Image from "next/image";
interface Button {
  title: string;
  href?: string;
  onClick?: () => void;
}
interface Content {
  title: string;
  description: string;
  img: string;
  isRowReverse: boolean;
  primaryButton?: Button;
  secondaryButton?: Button;
  backgroundColor: string;
  smallTitle?: string;
}

function Banner({ content }: { content: Content[] }) {
  const BannerUI = ({
    title,
    description,
    img,
    isRowReverse,
    primaryButton,
    secondaryButton,
    backgroundColor,
    smallTitle,
  }: Content) => {
    return (
      <div className={backgroundColor}>
        <div className="container py-3 py-md-5 banner-wrapper">
          {isRowReverse ? (
            <div className={`d-md-flex mt-5 pb-5 `}>
              <div className="col-md-5 col-lg-6 my-4 my-lg-0 text-center position-relative">
                <Image
                  src={img}
                  className="img-fluid"
                  alt="Top Banner"
                  loading={"lazy"}
                  width="575"
                  height="450"
                  layout={"intrinsic"}
                  style={{ maxHeight: 450, borderRadius: 10 }}
                />
              </div>
              <div className="caption align-items-md-end col-md-7 col-lg-6 d-flex flex-column ">
                <div className="col-md-10  mt-lg-5 pt-lg-5">
                  {smallTitle ? (
                    <p className="text-danger uppercase mb-2">{smallTitle}</p>
                  ) : null}
                  <h2 className="heavy-bold dark-text">{title}</h2>
                  <h3 className="grey-text mt-4">{description}</h3>
                </div>

                <div style={{ width: "83%" }}>
                  <div className="d-flex  flex-wrap mt-4" style={{ gap: 15 }}>
                    {primaryButton ? (
                      <button
                        onClick={() => router.push(primaryButton?.href)}
                        className="btn btn-danger py-3"
                      >
                        {primaryButton?.title}
                      </button>
                    ) : null}
                    {secondaryButton && (
                      <button
                        onClick={() => router.push(secondaryButton?.href)}
                        className="btn btn-white border-danger"
                      >
                        {secondaryButton?.title}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`d-flex flex-column-reverse flex-md-row mt-5 pb-5 `}
            >
              <div className="caption col-md-7 col-lg-6 d-flex flex-column ">
                <div className="col-md-10 mt-lg-5 pt-lg-5">
                  {smallTitle ? (
                    <p className="text-danger uppercase mb-2">{smallTitle}</p>
                  ) : null}
                  <h2 className="heavy-bold dark-text">{title}</h2>
                  <h3 className="grey-text mt-4 fs-18">{description}</h3>
                </div>
                <div className="d-flex flex-wrap mt-4" style={{ gap: 15 }}>
                  {primaryButton ? (
                    <button
                      onClick={() => router.push(primaryButton?.href)}
                      className="btn btn-danger py-3"
                    >
                      {primaryButton?.title}
                    </button>
                  ) : null}
                  {secondaryButton && (
                    <button
                      onClick={() => router.push(secondaryButton?.href)}
                      className="btn btn-white border-danger"
                    >
                      {secondaryButton?.title}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-5 col-lg-6 my-4 my-lg-0 text-center position-relative">
                <Image
                  src={img}
                  className="img-fluid"
                  alt="Top Banner"
                  loading={"lazy"}
                  width="575"
                  height="450"
                  layout={"intrinsic"}
                  style={{ maxHeight: 450, borderRadius: 10 }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      {content?.map((value, index) => {
        return (
          <BannerUI
            key={index}
            title={value?.title}
            description={value?.description}
            img={value?.img}
            primaryButton={value?.primaryButton}
            secondaryButton={
              value?.secondaryButton ? value?.secondaryButton : undefined
            }
            backgroundColor={value?.backgroundColor}
            isRowReverse={value?.isRowReverse}
            smallTitle={value?.smallTitle}
          />
        );
      })}
    </>
  );
}

export default Banner;
