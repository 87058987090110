import bannerImageCarCreditProgram from '../public/images/technician-updating.png';
import bannerTwo from '../public/images/homebanner/homebanner2.png';

import bannerImage1Fleet from '../public/images/homebanner/fleet-software.jpg';
import bannerImgTwoFleet from "../public/images/fleet/man-polish-car.png";

import bannerImageGarageWorks from '../public/images/car-service&repair.png';

import pricingImageHome from '../public/images/pricing.png';
import noQuotesImageHome from '../public/images/no-quotes-banner.png';

import bannerImageMembership from '../public/images/truck-bg.png';

import bannerTwoTechnicianHome from '../public/images/homebanner/homebanner2.png';
import bannerThreeTechnicianHome from '../public/images/homebanner/homebanner3.png';
import bannerFourTechnicianHome from '../public/images/homebanner/homebanner4.png';

export const customerReviews = [
    {
        name: 'Mejia K',
        address: 'Santa Clara, CA',
        description: `They provided excellent customer service. I needed to replace my
        brakes. I had gotten several estimates and I chose Paleo because they would consider
        saving the rotors $600. Upon inspection my rotors were done anyway. They offer
        shuttle service and in my case offered to pick up and drop off my car. That alone
        was amazing! Then they also cleaned a bit off my headlights. They sent me pictures
        in an effort of transparency as work was done.
    `,
        profileImage: 'https://s3-media0.fl.yelpcdn.com/photo/G_2-TRrAV6teC7mH4-IidA/300s.jpg',
        ratings: 5,
        link: 'https://www.yelp.com/user_details?userid=AOCGIIYhdQbalD8ASHI1iQ'
    },
    {
        name: 'Daniel E',
        address: 'Grapevine, TX',
        description: `This place rocks! They got me scheduled right away, kept in touch
        through every step of the process (including photos, which I appreciate), and got my
        van fixed quickly. They are right next door to a parts warehouse so they can get
        things done quick.

        I had some concerns after I got it back and they took it back into the shop with no
        hassle and no extra charge. AND it was 30% less than the other estimate I got!

        Thanks Paleo! You guys are the best!              
    `,
        profileImage: 'https://s3-media0.fl.yelpcdn.com/photo/7E7quasM8cNi_i5j-j__PA/300s.jpg',
        ratings: 5,
        link: 'https://www.yelp.com/user_details?userid=YpzfeCe92zgeGIBSBSOA2g'
    },
    {
        name: 'Lindsay G',
        address: 'San Antonio, TX',
        description: `Awesome shop!! Conveniently located downtown, Awesome staff. Took my
        car here and they listened to all of my concerns, they messaged me updating me at
        every step (including pictures). Didn't try to upsell me any nonessentials. Returned
        it wiped down and dusted the inside Very honest and upstanding company! Highly
        recommended!! Support Local!
    `,
        profileImage: 'https://s3-media0.fl.yelpcdn.com/photo/Ruzpd0hunusizLLn5b1zDg/300s.jpg',
        ratings: 5,
        link: 'https://www.yelp.com/user_details?userid=SIvcwJvEzavSQ4Bbo0SmXQ'
    },
    {
        name: 'TarynAshleigh M',
        address: 'San Antonio, TX',
        description: `Very grateful to have been taken care of by such a caring staff that
        truly blew my expectations away- I was very impressed by the personal touch of
        customer service unlike large dealerships, you'll alway be in good hands with Paleo
        Car Care Works!
    `,
        profileImage: bannerTwo.src,
        ratings: 5,
        link: 'https://s3-media0.fl.yelpcdn.com/photo/zxSYbTOYA2PwyktL7XDItQ/300s.jpg'
    },
    {
        name: 'Gaby B',
        address: 'San Antonio, TX',
        description: `Never liked taking my car in for repairs, but this time was different.
        Amazing customer service, reasonably priced, and best of all they did an excellent
        job repairing my vehicle. I am definitely recommending this garage to all my friends
        and family.
    `,
        profileImage: 'https://s3-media0.fl.yelpcdn.com/photo/e7yElFRBuExJX5LEHRMLlg/300s.jpg',
        ratings: 5,
        link: 'https://www.yelp.com/user_details?userid=bhNSULpYrzXZQTERobdUdQ'
    },
];

export const carCreditProgramTopContent = [{
    title: 'A full tank at your fingertips',
    description: 'Gas brought straight to your home, work, or wherever! All for a similar cost of pumping it yourself.',
    img: bannerImageCarCreditProgram.src,
    // primaryButton: {
    //     title: 'Order Gas',
    //     href: '/download',
    //     onClick: () => { }
    // },
    backgroundColor: 'bg-light-white',
    isRowReverse: false
}];

export const faqLeft = [
    { title: 'How does this work?', description: `Download and install our app. Choose between the Gas Delivery or Service & Repair button based on your specific needs. Provide the necessary details such as your location, preferred fuel type (if applicable), or the specific Service & Repair you require. Submit your order. From there, our dedicated team will take over and ensure a seamless and hassle-free experience.` },
    { title: 'Are there additional charges?', description: `We believe in transparency and fair pricing. There are no hidden charges when it comes to diagnosing your vehicle. We provide an honest and upfront pricing structure based on the specific issues identified during the diagnosis process. You can trust us to provide reliable and accurate estimates, ensuring you have a clear understanding of the costs involved.` },
    { title: 'Do I have to tip the driver?', description: `Tipping is completely optional. Our drivers are dedicated professionals committed to providing exceptional service, and their compensation is already taken care of. So, whether you choose to tip or not, rest assured that our team is focused on delivering top-notch service and meeting your needs.` }
];

export const faqRight = [
    { title: 'How long is the wait?', description: 'While the waiting time may vary depending on the number of orders in the queue, we assure you that our team is dedicated to delivering your service as promptly as possible. Our goal is to ensure that you receive your vehicle back in a timely manner, allowing you to get back on the road without unnecessary waiting.' },
    { title: 'Can you come and top up my car every week?', description: 'Simply subscribe to our weekly delivery option and choose a fixed date and time that suits your schedule. Our dedicated team will ensure that your car is fueled up at the specified time, without you having to make any additional stops or worry about running low on fuel.' },
    { title: 'I need oil change too. Can I add it to the order?', description: `Click on the ${`'`}Service & Repair${`'`} button on the homepage of the app to get started. By clicking on this button, you${`'`}ll gain access to a wide array of services designed to keep your vehicle running smoothly. Whether it${`'`}s routine maintenance, repairs, or any other car-related service, we${`'`}ve got you covered.`}
]

export const fleettopContent = [{
    title: 'Fleet Maintenance & Repair',
    description: `We offer end-to-end fleet maintenance and repairs with the best technicians. In-house parts provide access to over 100k sku${"'"}s greatly reducing turnaround time by not waiting on parts deliveries.`,
    img: bannerFourTechnicianHome.src,
    backgroundColor: 'bg-light-white',
    isRowReverse: false
},{
    title: 'Fleet Management Software',
    description: `Our fleet management app allows you to track, manage and service your fleet all while recommending preventative maintenance when they are due, greatly reducing downtime and expensive repairs.`,
    img: bannerImage1Fleet.src,
    backgroundColor: 'bg-light-white',
    isRowReverse: true
}];

export const fleettopContentHomePage = [{
    title: 'Fleet Maintenance & Repair',
    description: `We offer end-to-end fleet maintenance and repairs with the best technicians. In-house parts provide access to over 100k sku${"'"}s greatly reducing turnaround time by not waiting on parts deliveries.`,
    img: bannerFourTechnicianHome.src,
    backgroundColor: 'bg-light-white',
    isRowReverse: true,
    primaryButton: {
        title: 'Learn More',
        href: '/fleetrepairandmaintenance.html',
        onClick: () => { }
    }
}];

export const fleetMidContent = [{
    title: 'Free Concierge Service',
    description: 'We offer concierge service at no additional charge for fleet vehicles. This includes pickup and delivery of your vehicle and a courtesy wash because we understand your image on the road is everything.',
    img: bannerImgTwoFleet.src,
    backgroundColor: 'bg-light-white',
    isRowReverse: false,


}];


export const garageWorksTopContent = [{
    title: 'Bringing Together Drivers & Experts',
    description: 'Say goodbye to greedy garages, and say hello to the future of car care.',
    img: bannerImageGarageWorks.src,
    backgroundColor: 'bg-light-white',
    isRowReverse: false


}];

export const homeContent = [
    {
        title: 'Clear Pricing / No Quotas',
        smallTitle: 'Why Paleo',
        description: `We're here to serve you, not a middle man who sets a quota on repairs. We make our decisions based only on what you need.`,
        img: pricingImageHome.src,
        primaryButton: {
            title: 'Learn More',
            href: '/dealership.html',
            onClick: () => { }
        },
        backgroundColor: 'bg-white',
        isRowReverse: false
    },
]

export const membershipTopContent = [{
    title: 'Join the Paleo Family',
    description: 'From gas delivery to service & repair, we are always ready to help you get back on the road. So, why wait? Sign up today and experience the many benefits of being a member of our family.',
    img: bannerImageMembership.src,
    backgroundColor: 'bg-light-white',
    isRowReverse: false
}];

export const technicianHomeContent = [
    {
      title: 'You’ll Never Go to The Gas Station Again',
      description: 'Maecenas faucibus mollis interdum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
      img: bannerTwoTechnicianHome.src,
      primaryButton: {
        title: 'Order Gas',
        href: '/carcreditprogram.html',
        onClick: () => { }
      },
      secondaryButton: {
        title: 'Learn More',
        href: '/carcreditprogram.html',
        onClick: () => { }
      },
      backgroundColor: 'bg-light-white',
      isRowReverse: false


    },
    {
      title: 'Car Service & Repair',
      description: 'Maecenas faucibus mollis interdum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
      img: bannerThreeTechnicianHome.src,
      primaryButton: {
        title: 'View Services',
        href: 'car-repair-service',
        onClick: () => { }
      },
      secondaryButton: {
        title: 'Learn More',
        href: 'car-repair-service',
        onClick: () => { }
      },
      backgroundColor: 'bg-white',
      isRowReverse: true

    },
    {
      title: 'Fleet Management',
      description: 'Maecenas faucibus mollis interdum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
      img: bannerFourTechnicianHome.src,
      secondaryButton: {
        title: 'Learn More',
        href: '/fleetrepairandmaintenance.html',
        onClick: () => { }
      },
      backgroundColor: 'bg-light-white',
      isRowReverse: false

    }
  ]
